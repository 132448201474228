export const isLocalStorageAvailable = () => {
  const t = 't'
  try {
    window.localStorage.setItem(t, t)
    window.localStorage.removeItem(t)
    return true
  } catch (e) {
    return false
  }
}
