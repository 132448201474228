exports.components = {
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-error-page-js": () => import("./../../../src/templates/error_page.js" /* webpackChunkName: "component---src-templates-error-page-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-news-awards-js": () => import("./../../../src/templates/news/awards.js" /* webpackChunkName: "component---src-templates-news-awards-js" */),
  "component---src-templates-news-facts-js": () => import("./../../../src/templates/news/facts.js" /* webpackChunkName: "component---src-templates-news-facts-js" */),
  "component---src-templates-news-leaders-js": () => import("./../../../src/templates/news/leaders.js" /* webpackChunkName: "component---src-templates-news-leaders-js" */),
  "component---src-templates-news-life-at-envato-js": () => import("./../../../src/templates/news/life_at_envato.js" /* webpackChunkName: "component---src-templates-news-life-at-envato-js" */),
  "component---src-templates-news-logos-js": () => import("./../../../src/templates/news/logos.js" /* webpackChunkName: "component---src-templates-news-logos-js" */),
  "component---src-templates-news-news-feed-js": () => import("./../../../src/templates/news/news_feed.js" /* webpackChunkName: "component---src-templates-news-news-feed-js" */),
  "component---src-templates-news-product-shots-js": () => import("./../../../src/templates/news/product_shots.js" /* webpackChunkName: "component---src-templates-news-product-shots-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-tag-index-js": () => import("./../../../src/templates/tag/index.js" /* webpackChunkName: "component---src-templates-tag-index-js" */),
  "component---src-templates-tag-tag-js": () => import("./../../../src/templates/tag/tag.js" /* webpackChunkName: "component---src-templates-tag-tag-js" */)
}

