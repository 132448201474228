import React from 'react'
import Toast from '../components/toast_notification/toast_notification'
import { withPrefix } from 'gatsby-link'

const Layout = ({ children, path, data }) => (
  <>
    {children}
    {shouldShowPromoToast(path, data) ? <Toast /> : null}
  </>
)

export default Layout

function shouldShowPromoToast(pathname, data) {
  return (
    pathname !== withPrefix('/inside-envato/') &&
    !data?.post?.categories?.nodes?.some?.(({ slug }) => slug === 'inside-envato')
  )
}
