import classnames from 'classnames'
import React from 'react'

import css from './icon.module.css'

export const Checklist = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    role='img'
    aria-label='checklist'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14 4.75186L12.4521 3.30914L6.069 9.25288L3.55655 6.91116L2 8.35173L6.06884 12.1441L14 4.75186Z'
      fill='black'
    />
  </svg>
)

export const Link = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    role='img'
    aria-label='link'
    viewBox='0 0 12 12'
    width='12'
    height='12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 3.107v.226c0 .711-.283 1.393-.787 1.894l-1.88 1.886a.9.9 0 01-1.266 0L8 7.04a.333.333 0 010-.467l2.293-2.293c.245-.254.38-.594.374-.947v-.226c0-.356-.142-.696-.394-.947l-.433-.433a1.333 1.333 0 00-.947-.394h-.226c-.356 0-.696.142-.947.394L5.427 4a.333.333 0 01-.467 0l-.073-.073a.9.9 0 010-1.267L6.773.767A2.667 2.667 0 018.667 0h.226c.708 0 1.386.28 1.887.78l.44.44c.5.5.78 1.18.78 1.887zM3.767 7.293l3.526-3.526a.333.333 0 01.474 0l.466.466a.333.333 0 010 .474L4.707 8.233a.333.333 0 01-.474 0l-.466-.466a.333.333 0 010-.474zM7.04 8a.333.333 0 00-.467 0l-2.286 2.273a1.334 1.334 0 01-.954.394h-.226c-.356 0-.696-.142-.947-.394l-.433-.433a1.333 1.333 0 01-.394-.947v-.226c0-.356.142-.696.394-.947l2.28-2.293a.333.333 0 000-.467l-.074-.073a.9.9 0 00-1.266 0L.787 6.773A2.667 2.667 0 000 8.667V8.9c.001.705.282 1.381.78 1.88l.44.44c.5.5 1.18.78 1.887.78h.226a2.667 2.667 0 001.88-.76l1.9-1.9a.9.9 0 000-1.267L7.04 8z'
      fill='#717275'
    />
  </svg>
)

export const OutsideLink = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 16V10.5C18 10.2239 17.7761 10 17.5 10H16.5C16.2239 10 16 10.2239 16 10.5V16H2V2H7.5C7.77614 2 8 1.77614 8 1.5V0.5C8 0.223858 7.77614 0 7.5 0H2C0.89543 0 0 0.89543 0 2V16C0 17.1046 0.89543 18 2 18H16C17.1046 18 18 17.1046 18 16ZM17.51 0.23L17.78 0.5V0.49C17.9159 0.62558 17.9946 0.80811 18 1V7.5C18 7.77614 17.7761 8 17.5 8H16.5C16.2239 8 16 7.77614 16 7.5V3.42L7.56 11.85C7.46612 11.9447 7.33832 11.9979 7.205 11.9979C7.07168 11.9979 6.94388 11.9447 6.85 11.85L6.15 11.15C6.05534 11.0561 6.0021 10.9283 6.0021 10.795C6.0021 10.6617 6.05534 10.5339 6.15 10.44L14.59 2H10.5C10.2239 2 10 1.77614 10 1.5V0.5C10 0.223858 10.2239 0 10.5 0H17C17.1933 0.00801783 17.376 0.0904238 17.51 0.23Z'
      fill='#007475'
    />
  </svg>
)

export const Warning = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    role='img'
    aria-label='warning'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0.854937 12.5039C0.47399 13.1705 0.955357 14 1.72318 14H14.2768C15.0446 14 15.526 13.1705 15.1451 12.5039L8.86824 1.51943C8.48435 0.847611 7.51565 0.847611 7.13176 1.51943L0.854937 12.5039ZM8.72727 11.7895H7.27273V10.3158H8.72727V11.7895ZM8.72727 8.8421H7.27273V5.89474H8.72727V8.8421Z'
      fill='#B83C39'
    />
  </svg>
)

export const Twitter = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    role='img'
    aria-label='twitter'
    viewBox='0 0 16 16'
    width='16'
    height='16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.969 3.533c-.59.263-1.22.442-1.884.524a3.344 3.344 0 001.442-1.84 6.72 6.72 0 01-2.084.8 3.246 3.246 0 00-2.394-1.054c-1.811 0-3.28 1.489-3.28 3.323 0 .264.03.518.085.76-2.727-.13-5.143-1.458-6.76-3.465a3.294 3.294 0 00-.445 1.673c0 1.156.58 2.172 1.459 2.769a3.235 3.235 0 01-1.485-.416v.04c0 1.613 1.128 2.957 2.63 3.263a3.287 3.287 0 01-1.474.058c.42 1.32 1.63 2.283 3.069 2.31A6.521 6.521 0 010 13.655a9.236 9.236 0 005.038 1.493c6.035 0 9.332-5.066 9.332-9.453 0-.142 0-.284-.01-.426A6.676 6.676 0 0016 3.547l-.031-.014z'
      fill='#7E7E7E'
    />
  </svg>
)

export const Facebook = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    role='img'
    aria-label='facebook'
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M15.117 0H.883A.89.89 0 000 .895v14.433c0 .495.395.895.883.895h7.662V9.941H6.46V7.493h2.086V5.685c0-2.095 1.263-3.235 3.106-3.235.884 0 1.643.066 1.864.096v2.19h-1.28c-1 0-1.195.487-1.195 1.197v1.562h2.39l-.31 2.454h-2.08v6.274h4.077c.488 0 .883-.4.883-.895V.895A.889.889 0 0015.117 0z'
      fill='#7E7E7E'
    />
  </svg>
)

export const LinkedIn = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0H16C17.1046 0 18 0.89543 18 2V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0ZM5 15C5.27614 15 5.5 14.7761 5.5 14.5V7.5C5.5 7.22386 5.27614 7 5 7H3.5C3.22386 7 3 7.22386 3 7.5V14.5C3 14.7761 3.22386 15 3.5 15H5ZM4.25 6C3.42157 6 2.75 5.32843 2.75 4.5C2.75 3.67157 3.42157 3 4.25 3C5.07843 3 5.75 3.67157 5.75 4.5C5.75 5.32843 5.07843 6 4.25 6ZM14.5 15C14.7761 15 15 14.7761 15 14.5V9.9C15.0325 8.31078 13.8576 6.95452 12.28 6.76C11.177 6.65925 10.1083 7.17441 9.5 8.1V7.5C9.5 7.22386 9.27614 7 9 7H7.5C7.22386 7 7 7.22386 7 7.5V14.5C7 14.7761 7.22386 15 7.5 15H9C9.27614 15 9.5 14.7761 9.5 14.5V10.75C9.5 9.92157 10.1716 9.25 11 9.25C11.8284 9.25 12.5 9.92157 12.5 10.75V14.5C12.5 14.7761 12.7239 15 13 15H14.5Z'
      fill='#89898C'
    />
  </svg>
)

export const Instagram = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13 0H5C2.23858 0 0 2.23858 0 5V13C0 15.7614 2.23858 18 5 18H13C15.7614 18 18 15.7614 18 13V5C18 2.23858 15.7614 0 13 0ZM16.25 13C16.2445 14.7926 14.7926 16.2445 13 16.25H5C3.20735 16.2445 1.75549 14.7926 1.75 13V5C1.75549 3.20735 3.20735 1.75549 5 1.75H13C14.7926 1.75549 16.2445 3.20735 16.25 5V13ZM13.75 5.25C14.3023 5.25 14.75 4.80228 14.75 4.25C14.75 3.69772 14.3023 3.25 13.75 3.25C13.1977 3.25 12.75 3.69772 12.75 4.25C12.75 4.80228 13.1977 5.25 13.75 5.25ZM9 4.5C6.51472 4.5 4.5 6.51472 4.5 9C4.5 11.4853 6.51472 13.5 9 13.5C11.4853 13.5 13.5 11.4853 13.5 9C13.5027 7.80571 13.0294 6.65957 12.1849 5.81508C11.3404 4.97059 10.1943 4.49734 9 4.5ZM6.25 9C6.25 10.5188 7.48122 11.75 9 11.75C10.5188 11.75 11.75 10.5188 11.75 9C11.75 7.48122 10.5188 6.25 9 6.25C7.48122 6.25 6.25 7.48122 6.25 9Z'
      fill='#89898C'
    />
  </svg>
)

export const Website = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0ZM15.92 8H13.35C13.2943 6.1973 12.8937 4.42197 12.17 2.77C14.1948 3.80134 15.5929 5.75134 15.92 8ZM8.99998 16H9.44998C10.36 15.41 11.41 13.22 11.59 10H6.40998C6.54998 13.24 7.63998 15.43 8.54998 16H8.99998ZM6.45002 8C6.63002 4.78 7.68002 2.59 8.59002 2H9.00002H9.45002C10.36 2.57 11.45 4.76 11.59 8H6.45002ZM4.65002 8C4.70573 6.1973 5.10628 4.42197 5.83002 2.77C3.80524 3.80134 2.40706 5.75134 2.08002 8H4.65002ZM2.08002 10H4.65002C4.70573 11.8027 5.10628 13.578 5.83002 15.23C3.80524 14.1987 2.40706 12.2487 2.08002 10ZM13.35 10C13.2943 11.8027 12.8937 13.578 12.17 15.23C14.1948 14.1987 15.5929 12.2487 15.92 10H13.35Z'
      fill='#89898C'
    />
  </svg>
)

export const ChevronDown = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    role='img'
    aria-label='chevron down'
    width='14'
    height='8'
    viewBox='0 0 14 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.387.553a.333.333 0 01.473 0l.707.707a.32.32 0 010 .467L7.44 7.853A.5.5 0 017.087 8h-.174a.5.5 0 01-.353-.147L.433 1.727a.32.32 0 010-.467L1.14.553a.333.333 0 01.473 0L7 5.94 12.387.553z'
      fill='#424347'
    />
  </svg>
)

export const Menu = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='12'
    height='12'
    viewBox='0 0 12 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M3.333 0h-2C.597 0 0 .597 0 1.333v2c0 .737.597 1.334 1.333 1.334h2c.737 0 1.334-.597 1.334-1.334v-2C4.667.597 4.07 0 3.333 0zm0 3.333h-2v-2h2v2zM10.667 0h-2C7.93 0 7.333.597 7.333 1.333v2c0 .737.597 1.334 1.334 1.334h2c.736 0 1.333-.597 1.333-1.334v-2C12 .597 11.403 0 10.667 0zm0 3.333h-2v-2h2v2zm-6 5.334c0-.737-.597-1.334-1.334-1.334h-2C.597 7.333 0 7.93 0 8.667v2C0 11.403.597 12 1.333 12h2c.737 0 1.334-.597 1.334-1.333v-2zm-1.334 2h-2v-2h2v2zm7.334-3.334h-2c-.737 0-1.334.597-1.334 1.334v2c0 .736.597 1.333 1.334 1.333h2c.736 0 1.333-.597 1.333-1.333v-2c0-.737-.597-1.334-1.333-1.334zm0 3.334h-2v-2h2v2z'
      fill='#717275'
    />
  </svg>
)

export const Arrow = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='14'
    height='10'
    viewBox='0 0 14 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M.333 5.333v-.666c0-.184.15-.334.334-.334h10.78L8.48 1.373a.333.333 0 010-.473l.473-.467a.333.333 0 01.474 0L13.52 4.52a.5.5 0 01.147.353v.254a.513.513 0 01-.147.353L9.427 9.567a.333.333 0 01-.474 0l-.473-.474a.327.327 0 010-.466l2.967-2.96H.667a.333.333 0 01-.334-.334z'
      fill='#424347'
    />
  </svg>
)

export const Search = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='13'
    height='12'
    viewBox='0 0 13 12'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.25 8.304l2.667 2.67a.334.334 0 01-.014.46l-.466.467a.333.333 0 01-.474 0l-2.666-2.67a1.735 1.735 0 01-.2-.24l-.5-.667a4.663 4.663 0 01-2.914 1.02A4.674 4.674 0 012.648.46a4.663 4.663 0 015.68 1.315 4.677 4.677 0 01-.031 5.835l.666.461c.104.067.2.145.287.234zm-7.9-3.631a3.331 3.331 0 106.667 0 3.335 3.335 0 00-3.334-3.338A3.335 3.335 0 001.35 4.673z'
      fill='#717275'
    />
  </svg>
)

export const Close = ({ className, ...rest }) => (
  <svg
    {...rest}
    className={classnames(css.root, className)}
    width='8'
    height='8'
    viewBox='0 0 8 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.9 6.96a.333.333 0 010 .473l-.467.467a.333.333 0 01-.473 0L4 4.94 1.04 7.9a.333.333 0 01-.473 0L.1 7.433a.333.333 0 010-.473L3.06 4 .1 1.04a.333.333 0 010-.473L.567.1a.333.333 0 01.473 0L4 3.06 6.96.1a.333.333 0 01.473 0L7.9.567a.333.333 0 010 .473L4.94 4 7.9 6.96z'
      fill='#717275'
    />
  </svg>
)
