import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Transition } from 'react-transition-group'

import styles from './toast_notification.module.css'
import Link from '../link/link'
import { isLocalStorageAvailable } from '../../util/local_storage'

import { Arrow, Close } from '../icon/svgs'
import bauImg from './bau_image.jpeg'

// Most component logic copied from packages/frontdoor/src/components/survey_popup/survey_popup.js

const hash = (str) => [...str].reduce((a, b) => (Math.imul(31, a) + b.charCodeAt(0)) | 0, 0)
export const getPopupDismissedKey = (href) => `popup-dismissed-${hash(href)}`

const Toast = ({
  cta = 'Get Unlimited Downloads',
  text = '<b>Envato Elements:</b> Millions of creative assets. Unlimited downloads. One low cost.',
  href = 'https://elements.envato.com/?utm_campaign=envato-blog_toast-notification_elements&utm_medium=referral&utm_source=blog',
  imgSrc = bauImg
}) => {
  const duration = 800
  const defaultStyle = {
    transition: `all ${duration}ms ease`
  }
  const transitionStyles = {
    entering: { opacity: 1, transform: 'translateX(0)', pointerEvents: 'auto' },
    entered: { opacity: 1, transform: 'translateX(0)', pointerEvents: 'auto' },
    exiting: { opacity: 0, transform: 'translateX(-548px)', pointerEvents: 'none' },
    exited: { opacity: 0, transform: 'translateX(-548px)', pointerEvents: 'none' }
  }

  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScrollUpdate = () => setHasScrolled(window.scrollY > 0)

    document.addEventListener('scroll', handleScrollUpdate)
    return () => document.removeEventListener('scroll', handleScrollUpdate)
  })

  const [inProp, setInProp] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  useEffect(() => {
    if (dismissed && isLocalStorageAvailable()) {
      window.localStorage.setItem(getPopupDismissedKey(href), true)
    } else if (hasScrolled && isLocalStorageAvailable() && !window.localStorage.getItem(getPopupDismissedKey(href))) {
      setInProp(true)
    }
  }, [dismissed, href, hasScrolled, inProp])

  return (
    <Transition className='fade' in={inProp} timeout={duration}>
      {(state) => (
        <div className={styles.root} style={{ ...defaultStyle, ...transitionStyles[state] }}>
          <div className={styles.content}>
            <img alt='logo' src={imgSrc} className={styles.image} />
            <div className={styles.contentBody}>
              <span className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
              <Link href={href} className={styles.link}>
                {cta}
                <Arrow className={styles.arrow} />
              </Link>
            </div>
            <Close
              className={styles.close}
              alt='close'
              onClick={() => {
                setDismissed(true)
                setInProp(false)
              }}
            />
          </div>
        </div>
      )}
    </Transition>
  )
}

Toast.propTypes = {
  cta: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  imgSrc: PropTypes.string
}

export default Toast
