const { setupGoogleAnalytics } = require('@envato/google-analytics')

const renderTwitterEmbeds = () => {
  if (document.querySelector('.twitter-tweet') !== null) {
    if (typeof twttr !== 'undefined' && window.twttr.widgets && typeof window.twttr.widgets.load === 'function') {
      window.twttr.widgets.load()
    }
  }
}

const renderInstagramEmbeds = () => {
  if (document.querySelector('.instagram-media') !== null) {
    if (
      typeof instgrm !== 'undefined' &&
      window.instgrm.Embeds &&
      typeof window.instgrm.Embeds.process === 'function'
    ) {
      window.instgrm.Embeds.process()
    }
  }
}

const renderFacebookEmbeds = () => {
  if (document.querySelector('.fb-post') !== null) {
    if (typeof FB !== 'undefined' && window.FB.XFBML && typeof window.FB.XFBML.parse === 'function') {
      window.FB.XFBML.parse()
    }
  }
}

// Initialize Google Analytics on first render
// default Tracker ID is Envato
setupGoogleAnalytics()

exports.onRouteUpdate = ({ location }) => {
  // Wait to ensure page is rendered first.
  setTimeout(() => {
    renderTwitterEmbeds()
    renderInstagramEmbeds()
    renderFacebookEmbeds()
  }, 0)
}
