import React from 'react'
import GatsbyLink from 'gatsby-link'

/*
  Link is undefined in WordPress preview so it
  must be conditionally replaced with an anchor
  tag
*/
const Link = ({ to, href, children, ...props }) => {
  const location = to || href

  return GatsbyLink && !location.startsWith('http') ? (
    <GatsbyLink to={location} {...props}>
      {children}
    </GatsbyLink>
  ) : (
    <a href={location} {...props}>
      {children}
    </a>
  )
}

export default Link
